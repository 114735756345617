// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500&display=swap');@import url("../node_modules/bootstrap/dist/css/bootstrap.css");
@import "~@angular/material/theming";
$theme-colors: ("color1": #000, );
@import "~swiper/swiper-bundle";
@import "~bootstrap/scss/bootstrap";
@include mat-core();
@import "./color-palette";
$default-primary: mat-palette($pallet);
$default-accent: mat-palette($pallet-accent, A200, A100, A400);
$default-warn: mat-palette($mat-red);
$default-theme: mat-light-theme($default-primary, $default-accent, $default-warn);
@include angular-material-theme($default-theme);
@import '~ngx-sharebuttons/themes/material/material-dark-theme';
@import '~ngx-sharebuttons/themes/modern/modern-light-theme';
@import "~swiper/swiper-bundle";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";
@import url('./assets/css/font-awesome.css');
@import url('./assets/css/fonts-googleapis.css');
@import url('./assets/css/fonts-googleapis-font.css');
@import url('./assets/css/fonts-poppins.css');



@font-face {
  font-family: 'bebas_neuelight';
  src: url('./assets/fonts/bebasneue_light-webfont.woff2') format('woff2'),
    url('./assets/fonts/bebasneue_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('./assets/fonts/BebasNeue-Regular.woff2') format('woff2'),
    url('./assets/fonts/BebasNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  outline: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  transition: ease 0.3s;
  cursor: pointer;
}

button {
  transition: ease 0.3s;
  cursor: pointer;
}

.cp {
  cursor: pointer;
}

app-loader {
  display: block;
}

.mat-icon {
  vertical-align: middle;
  margin: 0;
}

@mixin DYNAMIC_WIDTH_PERCENTAGE {
  @for $i from 1 through 100 {
    .width-#{$i} {
      width: #{$i + "%"};
    }
  }
}

@include DYNAMIC_WIDTH_PERCENTAGE;

@mixin DYNAMIC_WIDTH_PEXELS {
  @for $i from 1 through 2000 {
    .pxwidth-#{$i} {
      width: #{$i + "px"};
    }
  }
}

@include DYNAMIC_WIDTH_PEXELS;

html.i-amphtml-fie>body,
html.i-amphtml-singledoc>body.body-active {
  overflow: hidden !important;
}

html:not([amp4ads]),
html:not([amp4ads]) body.body-active {
  height: 100vh !important;
}

body,
html {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  color: #000000;
  background: #fff;
  position: relative;
  font-size: 16px;
  line-height: 23px;

  &.bodyActive {
    overflow: hidden;
    .submenuHas {
      >a {
        &:before {
          display: block !important;
        }
      }
    }
  }

  &.activeFilter {
    .listingInner {
      .sidebar {
        left: 0 !important;
      }
    }
  }

  &.body-active {
    height: 100vh;

    &:before {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      content: "";
      z-index: 9999;
      transition: 0.3s all ease-in-out 0s;
    }

    .sidebarFilter {
      right: 0 !important;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

a>* {
  cursor: pointer;
}

a:hover {
  color: #616161;
  text-decoration: none;
}

a:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  color: #000;
  padding: 0;
  margin: 0 0 20px 0;
}

h1 {
  font-size: 43px;
  line-height: 52px;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (max-width: 479px) {
    font-size: 26px;
    line-height: 37px;
  }
}

h2 {
  font-size: 40px;
  line-height: 45px;
  color: #222222;
  margin-bottom: 30px;

  @media (max-width: 1199px) {
    font-size: 30px;
    line-height: 35px;
  }
}

h3 {
  font-size: 19px;
  line-height: 24px;
  font-weight: 400;

  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 22px;
  }
}

h4 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;

  @media (max-width: 1199px) {
    font-size: 15px;
    line-height: 19px;
  }
}

h5 {
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 12px;
}

h6 {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
}

p {
  margin: 0 0 20px 0;
}

ol {
  padding-left: 20px;
}

ol li {
  margin-bottom: 5px;
}

ul,
li {
  padding: 0;
  margin: 0;
}

ul li {
  padding: 0;
  position: relative;
  list-style: none;
}

a {
  transition: 0.3s all ease-in-out 0s;
  cursor: pointer;
}

.dropdown-toggle:focus {
  outline: none;
}

select {
  appearance: none;
  background: transparent;
  border-radius: 0;
  width: 100%;
  height: 50px;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  border: 1px solid #000;
  font-family: "Poppins", sans-serif;
  padding-right: 30px;
  background: url(./assets/images/down-arrow.svg) no-repeat right 10px center;
  background-size: 15px;
  appearance: none;
  cursor: pointer;
}

select[multiple] {
  background: transparent;
  min-height: 85px;
}

:placeholder {
  color: #000;
  opacity: 1;
}


button {
  background: transparent;
  box-shadow: none;
  border: 0;
  transition: 0.3s all ease-in-out 0s;
  border-radius: 0;
}

.gapsec {
  padding: 45px 0;

  @media (max-width: 767px) {
    padding: 32px 0;
  }
}

.btn,
.btn2 {
  display: inline-block;
  padding: 15px 31px;
  background: #101010;
  color: #ffffff;

  font-size: 16px;
  line-height: 20px;
  border: 1px solid #101010;
  border-radius: 0;

  &:hover {
    background: transparent;
    color: #101010;
  }
}

.btn2 {
  background: transparent;
  color: #000;

  &:hover {
    color: #fff;
    background: #000;
  }
}

.readmorebtn {
  text-decoration: underline;
  color: #000000;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}

.breadcrumbsec {
  text-align: center;
}

label {
  display: inline-block;
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 14px;
}

.form-group {
  margin-bottom: 30px;
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border-radius: 0 !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background: #000;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #000;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background: rgba(0, 0, 0, 1);
}

.mat-checkbox-frame,
.mat-radio-outer-circle {
  border: 1px solid #000;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 1;
}

.mat-checkbox-layout {
  white-space: inherit !important;

  .mat-checkbox-inner-container {
    margin-top: 0;
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
}

mat-radio-button {

  .mat-radio-container,
  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    width: 30px;
    height: 30px;
  }
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none;
}

.star-empty,
.star-full,
.star-half {
  width: 20px !important;
  height: 22px !important;
}

.desktop {
  @media (max-width: 767px) {
    display: none;
  }
}

.mobile {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.loginRegWrap {
  @media (min-width: 1600px) {
    font-size: 22px;
  }

  font-size: 18px;

  label {
    color: #000 !important;
  }
}

.mat-horizontal-stepper-header-container {
  padding: 15px;
  margin-bottom: 10px;
  user-select: none;

  .mat-horizontal-stepper-header {
    height: auto;
    padding: 0;
    display: block;
    border-radius: 50%;
    overflow: hidden;

    .mat-step-icon {
      width: 56px;
      height: 56px;
      line-height: 56px;
      border-radius: 50%;
      background-color: #dbdada;
      color: #414141;
      display: block;
      font-size: 26px;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
      transition: ease 0.3s;
      margin: 0;
    }

    .mat-step-icon-selected {
      background-color: #101010;
      color: #fff;
    }

    .mat-step-label {
      display: none !important;
    }
  }

  .mat-stepper-horizontal-line {
    margin: 0;
    border-top-width: 4px;
    border-top-color: #dbdada;
  }
}

@media (max-width: 767px) {
  .loginRegWrap {
    .h2 {
      font-size: 1.3rem;
      margin-bottom: 1.5rem;
    }

    .btn {
      width: 100%;
      margin-top: 15px;
      padding: 12px 22px;
      font-size: 14px;
    }
  }

  .mat-horizontal-content-container {
    padding: 0 !important;
  }

  .mat-horizontal-stepper-header-container {
    .mat-horizontal-stepper-header {
      .mat-step-icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
    }

    .mat-stepper-horizontal-line {
      min-width: unset;
      border-top-width: 2px;
    }
  }
}

.img-zoom-result {
  background-color: #fff !important;
}

.contactform {

  textarea,
  input:not([type="checkbox"]),
  input:not([type="radio"]) {
    appearance: none;
    background: transparent;
    border-radius: 0;
    width: 100%;
    height: 56px;
    padding: 0px 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000;
    border: 1px solid #333;
    font-family: "Poppins", sans-serif;
  }

  textarea {
    height: 150px;
    padding: 15px;
    line-height: normal;
  }
}


.swal2-title {
  font-size: 14px !important;
  line-height: 20px !important;
  text-transform: none !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  font-family: "Poppins", sans-serif !important;
}

.swal2-success-line-long,
.swal2-success-line-tip {
  background: #878787 !important;
}

.swal2-success-ring {
  border: 0.25em solid rgba(0, 0, 0, .30) !important;
}

input:not(.country-search) {
  color: #000;
}

.ngx-pagination {
  margin-top: 30px;

  li.current {
    background: #000;
  }
}

.canvas-container {
  margin-top: 0 !important;

  .canvas-container {
    margin-top: 0 !important;
  }
}

mat-spinner {
  width: 30px !important;
  height: 30px !important;

  svg {
    width: 100% !important;
    height: 100% !important;
  }
}


.img-zoom-lens {
  width: 75px !important;
  height: 120px !important;
}


.container-fluid {
  padding: 0 50px !important;

  @media (max-width: 1600px) {
    padding: 0 15px !important;
  }
}

.common-headings {
  padding-bottom: 40px;

  @media (max-width: 767px) {
    text-align: left !important;
    padding-bottom: 13px;
  }

  h2 {
    font-weight: 400;
    font-size: 52px;
    line-height: 70px;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 10px;

    @media(max-width:1024px) {
      font-size: 45px;
      line-height: 55px;
    }

    @media(max-width: 767px) {
      font-size: 21px;
      line-height: 28px;
    }
  }

  p {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #868686;

    @media(max-width:767px) {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: #626060;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}


.img-zoom-container {
  position: relative;
}

.img-zoom-lens {
  position: absolute;
  width: 40px;
  height: 40px;
}

.img-zoom-result {
  border: 1px solid #d4d4d4;
  width: 300px;
  height: 300px;
}

.bodyActivemodal {
  overflow: hidden;
}

.none {
  display: none !important;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #cbcbcb;

  @media screen and (max-width: 852px) {
    background-color: transparent;
  }
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #cbcbcb;

  @media screen and (max-width: 852px) {
    background-color: transparent;
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #b7b7b7;

  @media screen and (max-width: 852px) {
    background-color: transparent;
  }
}

.form-control:focus {
  box-shadow: none;
}

.swal2-container {
  z-index: 9999 !important;
}

.swal2-popup.swal2-toast {
  box-shadow: 0 0 0.625em rgba(0, 0, 0, .58) !important;
}


canvas {
  pointer-events: none;
}

input,
textarea {
  @media(max-width:1024px) {
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.swal2-title {
  padding-left: 5px !important;
  border-left: 2px solid #ccc;

}
.row{
  &.no-margin{
    margin: 0 !important;
  }
}